import React, { useState, useEffect } from 'react';
import PageHeading from '../components/page/pageHeading'
import { FearGreedIndex } from '../components/apis/fearGreedIndex'
import { CryptoCurrencyMarket, AdvancedRealTimeChart, CopyrightStyles } from 'react-ts-tradingview-widgets'
import { PostRequest } from "../services/api/backend";

class AnalyticsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        openInterest: [],
        openInterestLoaded: false
    };
  }

  componentDidMount() {
    const data = {
      endpoint: "/futures/openInterest?symbol=BTC&interval=0"
    }
    PostRequest("coinglass/auth", data)
      .then(res => {
        console.log("result GetRequestAuthCoinglass", res.data.data);
        this.setState({
            openInterest: res.data.data,
            openInterestLoaded: true
        });
      }).catch((err) => {
        console.log("error GetRequestAuthCoinglass", err);
    });
  }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }


  render() {
    const styles = CopyrightStyles = {
        parent: {
            fontSize: "1px",
            color: "white"
        },
        span: {
            color: "white"
        }
    }
    return (
        <>
            <PageHeading pageTitle="Analytics" />

            <div className="container mb-6">
              <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                
                  <div className="px-4 py-5 bg-skin-card shadow rounded-lg overflow-hidden sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">Fear & Greed Index</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900"><FearGreedIndex /></dd>
                    <dd><a href="https://alternative.me/crypto/fear-and-greed-index/" target="_blank">Info</a></dd>
                  </div>
                  <div className="px-4 py-5 bg-skin-card shadow rounded-lg overflow-hidden sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">Sentiment</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">Netural</dd>
                  </div>
                  <div className="px-4 py-5 bg-skin-card shadow rounded-lg overflow-hidden sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">Fear & Greed Index</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">100</dd>
                  </div>
            
              </dl>
            </div>

            <div className='container'>
                <h1 className='text-2xl'>Heatmap</h1>
                <iframe
                      id="xxx"
                      title="xxx"
                      width="100%"
                      height="420"
                      frameBorder="value"
                    //   allowtransparency
                      srcDoc={`
                          <qc-heatmap height="400px" num-of-coins="20" currency-code="USD"></qc-heatmap>
                              <script src="https://quantifycrypto.com/widgets/heatmaps/js/qc-heatmap-widget.js"></script>
                          
                          `}
                 />
            </div>

            <div className="container">
                <CryptoCurrencyMarket colorTheme="light" width="100%" height={400} copyrightStyles={styles}></CryptoCurrencyMarket>
            </div>

            <div className="container h-[800px]">
                <AdvancedRealTimeChart theme="light" copyrightStyles={styles} symbol='btcusd' autosize></AdvancedRealTimeChart>
            </div>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                
            <div>
                {!this.state.openInterestLoaded ? (
                    <div>Loading open interest ...</div>
                ) : (
                    <div className = "App">
                    <h1> Open Interest </h1>  {
                            this.state.openInterest.map((key, i) => ( 
                                <ol key = { i } >
                                Exchange: { key.exchangeName }, 
                                Open Interest: { key.openInterest }
                            </ol>
                            ))
                        }
                    </div>
                )}
            </div>
        </div>
        </>
    );
  }
}
export default AnalyticsHome;