import React from 'react'

const PageHeading = ({ pageTitle }) => {

    return (
        <div className='container pb-3 mb-3'>
            <h3 className='text-4xl capitalize font-medium text-white'>{ pageTitle }.</h3>
        </div>
    )
}

export default PageHeading