import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { PublicGetRequest } from '../../services/api/public';

export const FearGreedIndex = () => {
    const [fgArray, setFgArray] = useState([])

    const fetchFg = async () => {
        const response = await axios("https://api.alternative.me/fng/");
        console.log(response.data.data?.[0]);
        setFgArray(response.data.data?.[0]);


        PublicGetRequest("https://api.alternative.me/fng/")
        .then(res => {
          console.log("result api.alternative", res.data.data);
          setFgArray(res.data.data?.[0]);
        }).catch((err) => {
          console.log("error api.alternative", err);
      });

    }

    useEffect(() => {
        fetchFg();
        console.log(fgArray.value)
    }, []);

    return (
        <>
            <span>{fgArray.value}</span>
        </>
    )
}