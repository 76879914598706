import React from "react";
import { Link, navigate } from "gatsby"
import Axios from "axios"


// Public GET request
const PublicGetRequest = async (endpoint) => {
    const url = endpoint;
    return Axios.get(url);
};

// Public POST request
const PublicPostRequest = async (endpoint, data) => {
    const url = endpoint;
    return Axios.post(url, data);
};

export {
    PublicGetRequest,
    PublicPostRequest
};